import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Button, Typography } from 'amphitheatre';

import Layout from 'src/Layout';

import Container from 'src/Components/ContentContainer';
import ToTop from 'src/Components/ToTop';

import * as Base from 'src/page-styles/base.styled';

import Step1Image from 'src/assets/images/tutorials/step2/pic1.png';
import Step2Image from 'src/assets/images/tutorials/step2/pic2.png';
import Step3Image from 'src/assets/images/tutorials/step2/pic3.png';
import Step4Image from 'src/assets/images/tutorials/step2/pic4.png';

import Step1EditImage from 'src/assets/images/tutorials/step2/pic1edit.png';
import Step2EditImage from 'src/assets/images/tutorials/step2/pic2edit.png';

const { Header, Paragraph } = Typography;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

function Step1(): JSX.Element {
  return (
    <Layout>
      <ToTop />
      <Container.Dark>
        <Base.MenuGap />
        <Base.Heading>Tutorial 2: Satellites</Base.Heading>
        <Base.Text>
          <Header level={3}>Step 1</Header>
          <Base.Image src={Step1Image} />
          <Paragraph>
            Click the ‘Satellite’ button on the P.I.G.I. menu wheel.
          </Paragraph>
          <Paragraph>
            This should open the ‘Add Satellite’ dialog onto the screen above
            the main menu.
          </Paragraph>
          <Base.Gap />
          <Header level={3}>Step 2</Header>
          <Base.Image src={Step2Image} />
          <Paragraph>
            There are a few options for you to add satellites to your scene. The
            most important for this quest will be the custom satellite and the
            satellite catalogue. Clicking on either will cause a dialog box to
            appear on screen.
          </Paragraph>
          <Base.Gap />
          <Header level={3}>Step 3</Header>
          <Base.Image src={Step3Image} />
          <Paragraph>
            Satellite Catalogue allows you to select from a catalogue or
            existing satellites which are in our orbit. You are able to search
            by both satellite name and ID on the left, and sort and filter by
            various categories.Find a satellite and ensure the appropriate model
            of satellite is selected.
          </Paragraph>
          <Base.Gap />
          <Header level={3}>Step 4</Header>
          <Base.Image src={Step4Image} />
          <Paragraph>
            Custom Satellite allows you to create a satellite and view its data
            according to your parameters. In this case, adjust the 6 Keplerian
            elements to your needs, select the model and click ‘Create
            Satellite’. Your custom satellite should appear on screen.
          </Paragraph>
          <Base.Gap />
        </Base.Text>
        <Base.Heading>Editing</Base.Heading>
        <Base.Text>
          <Header level={3}>Step 1</Header>
          <Base.Image src={Step1EditImage} />
          <Paragraph>
            Selecting your satellite will cause a dialog box to appear on the
            left of your screen. If the dialog does not appear, ensure your
            satellite is selected and click the blue ‘info’ button on the top
            left of the screen.
          </Paragraph>
          <Paragraph>
            Navigate to ‘Settings’ which is the third tab, click on ‘Edit
            Satellite’ which will bring up a dialog box to the right.
          </Paragraph>
          <Base.Gap />
          <Header level={3}>Step 2</Header>
          <Base.Image src={Step2EditImage} />
          <Paragraph>
            Changing the values of any of the 6 Keplerian elements will affect
            your satellite’s orbit and change your overpass data.
          </Paragraph>
          <BtnGroup>
            <Link to="/tutorials/tutorial1" style={{ textDecoration: 'none' }}>
              <Button ghost>Prev</Button>
            </Link>
            <Link to="/tutorials/tutorial3" style={{ textDecoration: 'none' }}>
              <Button>Next</Button>
            </Link>
          </BtnGroup>
        </Base.Text>
      </Container.Dark>
    </Layout>
  );
}

export default Step1;
